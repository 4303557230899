import MainMenu from './menu/MainMenu';

import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import Link from "next/link";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRightArrowLeft,
    faCoins,
    faAnglesRight, faLock,
    faUser, faUsers, faBuilding,
    faSignalBars,
    faCreditCard, faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import {getCookie, setCookie} from "cookies-next";

import * as Sentry from "@sentry/nextjs";
import DialogSwitchTeams from './dialogs/DialogSwitchTeams';
import {borderRadius3, borderRadius50percent} from '../global/Variables.js';
import { FormattedMessage } from 'react-intl';
import messages from '../../../src/messages';
import { GlobalButtonFinal, ModalsWrapper, ModalWindow } from './GlobalStyles';
import ObaleroFileUploader from './ObaleroFileUploader';
import Compressor from 'compressorjs';
import { LoaderProducts } from '../containers/Dashboard';
import DialogAlert from './dialogs/DialogAlert';
import { useRouter } from 'next/router';




export const SideMenu  = styled.div`
  width: 15%;
  height: 100%;
  position: fixed;
  background: var(--black-5, #F2F2F2);
  padding:22px;
    padding-top:77px;




    .logo{

        width:48px;

        height: 41px;
        display: inline-block;
        top: 0px;
        text-align: center;
        position: relative;

        img{
            width:48px;
            border-radius:50%;
            height:48px;
            max-width:100%;
            position:absolute;
            transition:all 0.3s ease;
        }

        >img{
            width:48px;
            border-radius:50%;
            height:48px;
        }
        >div{
            //background:var(--black-15, #D9D9D9);
            border-radius:50%;
            width: 48px;
            height: 48px;
            padding-top: 12px;
            svg{
                color:white !important;
                text-align: center;
                font-size: 24px !important;
                font-style: normal;
                font-weight: 900 !important;
                line-height: normal;
                top:3px;
                path{
                    fill:white;
                }

            }

            .unhovered{

            }
            .hovered{
                display:none;
            }

            &:hover{


                background:#3D55D7;
                transition:all 0.3s ease;
                cursor:pointer;
                img{
                    opacity:0;
                }
                .unhovered{
                    display:none;
                }
                .hovered{

                    display:inline-block;
                }


            }
        }

        &.nohover{
            >div:hover{
                background:var(--black-15, #D9D9D9) !important;
            }
        }

    }

    
    .mn-sub {
min-width:250px;
    width: 100%;
height:100%;
    //overflow-y:scroll;
 
    border-bottom-left-radius: 22px;
    border-bottom-right-radius:0;
    right: -24px !important;

    transition: all 0.3s ease;
      border-radius: 0 !important;
    top: 0;
padding:0;

      overflow-y: scroll;
      padding-bottom: 50px;
      min-width: 190px;
      
   
      
      .switchTeamsBtn{
          &:hover{
              cursor:pointer;
          }
      }

    .dividerText{
      font-size: 18px !important;
      position: relative;
      color: rgba(255, 255, 255, 0.6);

      text-align: center;


      font-style: normal;
      font-weight: 300;
      line-height: normal;
      width: 90%;
      position: relative;
      margin:auto auto;
      display: block;

      &:before {
        content: "";
        display: block;

        height: 1px;
        background: rgba(255, 255, 255, 0.6);
        left: 0;
        top: 50%;
        width: 70px;
        position: absolute;
      }


      &:after {
        content: "";
        display: block;
        width: 70px;
        height: 1px;
        background: rgba(255, 255, 255, 0.6);
        right: 0;
        top: 50%;
        position: absolute;
      }

      &.team{
        &:before {
          width: 60px;
        }


        &:after {
          width: 60px;
        }
      }

    }

    .teamSwitcher {
      img {
        margin-right: 10px;
        //max-width: 15px;
        //
        //width: 15px;
        //height: 15px;
      }

      svg {
        //width: 15px;
        margin-right: 10px;
      }

      &.active {
        background: rgba(255, 255, 255, .09);
        color: #f5f5f5 !important;
      }
      span{
        color: var(--black-40, #999);
        font-family: "Nunito Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    &.show {
      //   display:block;
      visibility: visible;
      opacity: 1 !important;
      //transform: translateY(0);
    }

    .userInfo {
      text-align: left !important;


      h5 {



        text-align: left !important;
        margin-top: 4px;

        color: var(--black-70, #4C4C4C);
        font-family: "Nunito Sans";
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400  !important;
        line-height: normal;
        margin-bottom:0 !important;

      }

      span {

        color: var(--black-40, #999) !important;
        font-family: "Nunito Sans";
        font-size: 16px  !important;
        font-style: normal !important;
        font-weight: 300  !important;
        line-height: normal;
      }

      .plan {
        color: var(--black-100, #000) !important;
        font-family: "Nunito Sans" !important;
        font-size: 10px !important;
        font-style: normal;
        padding: 1px 5px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: normal;
        border-radius: ${borderRadius3};
        background: var(--black-40, #999) !important;
      }
      .credits{
        color: var(--black-15, #D9D9D9) !important;
        font-family: "Nunito Sans";
        font-size: 10px !important;
        font-style: normal !important;

        font-weight: 700;
        line-height: normal;
        margin-left:10px;
      }

      hr {
        width: 85%;
        background: lightgray;
        height: 2px;
        opacity: 1;
        margin: auto;
      }

      img{
        width:48px;
          border-radius: ${borderRadius50percent};
        height:48px;
      }
    }
    
    .teamInfo{
      text-align: left !important;
         margin-top:30px !important;

      h5 {



        text-align: left !important;
        margin-top: 15px;

        color: var(--black-70, #4C4C4C);
        font-family: "Nunito Sans";
        font-size: 16px  !important;
        font-style: normal  !important;
        font-weight: 400  !important;
        line-height: normal;
        margin-bottom:0 !important;

      }

      span {

        color: var(--black-40, #999) !important;
        font-family: "Nunito Sans";
        font-size: 14px  !important;
        font-style: normal !important;
        font-weight: 300;
        line-height: normal;
      }

      .plan {
        color: var(--black-100, #000) !important;
        font-family: "Nunito Sans" !important;
        font-size: 10px !important;
        font-style: normal;
        padding: 1px 5px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: normal;
          border-radius: ${borderRadius3};
        background: var(--black-40, #999) !important;
      }
      .credits{
        color: var(--black-15, #D9D9D9) !important;
        font-family: "Nunito Sans";
        font-size: 10px !important;
        font-style: normal !important;

        font-weight: 700;
        line-height: normal;
        margin-left:10px;
      }

      hr {
        width: 85%;
        background: #D9D9D9;
        height: 2px;
        opacity: 1;
        margin: auto;
      }

      img{
        width:48px;
        height:48px;
          border-radius: ${borderRadius50percent};
      }
    }

  }

  .mn-sub li {

    width: 100%;
    margin: 0 !important;

    float: left;
    position: relative;
    //line-height: 3.9;
    display: block;
    width: 100%;
    margin-top:20px !important;
    transition:all 0.3s ease;
    a,span{
      color: var(--black-60, #666);
      /* Top Menu/Item */
      font-family: "Nunito Sans";
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400  !important;
      transition:all 0.3s ease;
      line-height: normal;      
    }
    svg{
      width:16px;
      transition:all 0.3s ease;
      height:auto;
    }
    &:hover,
    &.active{
      span,
      svg{
        color: var(--black-80, #333) !important;   
      }
     
    }
    
  }
    

    hr {
      width: 85%;
      background: #D9D9D9;
      height: 1px;
      opacity: 1;
 
      border: none !important;
      margin-top: 61px;
      display: block;
      position: relative;
      top: 10px;
    }


    
    &.inactiveMobile{
        img,
        p,
        span,
        h5,
        hr{
            display: none;
        }
    }
    @media (max-width:1400px){
        width:20%;
    }
    
    @media (max-width:1100px){
        width:25%;
    }

    @media (max-width:900px){
        width:30%;
    }

    @media (max-width:700px){
        width:35%;
    }

    @media (max-width:500px){
        width:60px;
        overflow:hidden;
    }



`;

export const MainLayout  = styled.div`
padding-top:55px;
  width: 100%;
  height: 100%;
  position: absolute;

  &.editor{
    width:100%;
      top: 54px;
    overflow:hidden;
    height: calc(100% - 54px);
  }
  &.isLogin{
    width:100%;
  }

    
  &.isSideMenu{
    width:85%;
    background: white;
    margin-left:15%;
  }

    @media (max-width:1400px){
        &.isSideMenu{
            width:80%;
            margin-left:20%;
        }
    }
    
    @media (max-width:1100px){
        &.isSideMenu{
            width:75%;
            margin-left:25%;
        }
    }
    @media (max-width:900px){
        &.isSideMenu{
            width:70%;
            margin-left:30%;
        }
    }


    @media (max-width:700px){
        &.isSideMenu{
            width:65%;
            margin-left:35%;
        }
    }


    @media (max-width:500px){
        &.isSideMenu{

            width: calc(100% - 60px);
            margin-left:60px;
        }
        
        h3{
            font-size: 23px !important;
        }
    }
    
    //&.editor{
  //  height: calc(100% - 71px);
  //}

`;

function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}


export default function Layout ({children, showMainMenu, typeShareView,productNameMaster,reloadInfo,
                                    setReloadInfo,
                                    productVersionNameMaster,breadcrumbsAPI,
                                   showSideMenu, typeEditor, typeEditorPlugin, sideMenuPage,isLogin,
                                     masterProductUUID,   versionProductUUID,
                                    versionIsLocked, setVersionIsLocked, teamCredits, setTeamCredits, handleClick,hitTriggerShowRender}) {

    const size = useWindowSize();

    const [showMobileMenu, setShowMobileMenu] = useState(false);



    const [userAvatar, setUserAvatar] = useState('default');
    const [userEmail, setUserEmail] = useState('default email');
    const [userName, setUserName] = useState('default');
    const [userUUID, setUserUUID] = useState('default');
    const [userTeams, setUserTeams] = useState([]);
    const [teamAvatar, setTeamAvatar] = useState('default');
    const [appExternal, setAppExternal] = useState(false);

    const [userRole, setUserRole] = useState("user");
    const router = useRouter();

    const loadUserInfoAPI = () => {

        var tokenMain = getCookie('userTeam');

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'auth/info', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + tokenMain, // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data) {

                if(data.status === 401){

                } else {


                    if (data.user.avatar && data.user.avatar !== undefined) {
                        setUserAvatar(data.user.avatar);
                    }

                    setLoadProductsShow(false);
                    setUserEmail(data.user.email);
                    setUserName(data.user.full_name);

                    if(data.user.full_name.length>12){
                        setUserName(data.user.name);
                    }
                    setUserUUID(data.user.uuid);
                    setUserTeams(data.teams);

                    if (!getCookie('userMail') && (getCookie('userMail') !== data.user.email)) {
                        setCookie('userMail', data.user.email, { maxAge: 10 * 365 * 24 * 60 * 60 });
                    }

                    Object.keys(data.teams).map(key => {
                        if (getCookie('userTeamUUID') === data.teams[key]['uuid']) {
                            setTeamAvatar(data.teams[key]['logo']);


                            setUserRole( data.teams[key]['role']);



                            if(setTeamCredits && data.teams[key]['role'] !== "external"){
                                setTeamCredits(data.teams[key]['credits']);
                            } else{
                                if( data.teams[key]['role'] ==="external"){
                                    setAppExternal(true);

                                }
                            }

                        }

                    });

                }
                if (data.message !== undefined) {
                    Sentry.captureException('auth/info' + ': ' + data.message + ' : ' + getCookie('userMail'));
                }

            });
    };


    useEffect(() => {

        if (userAvatar === 'default' && getCookie('userTeam')) {
            loadUserInfoAPI();

        }

    }, []);


    useEffect(() => {

                if(reloadInfo){
                    loadUserInfoAPI();
                    setReloadInfo(false);
                }
    }, [reloadInfo]);

    let handleClickSwitchTeams = () => {

    };



    const [showNotifications, changeShowNotifications] = useState(false);
    const [showUserMenu, changeShowUserMenu] = useState(false);
    const closeModalOutsideClickMenu = (e) => {
     /*   if(window.Intercom){
            window.Intercom("hide");
        }*/

        if( e.target.getAttribute("data-closeintercombreak") !== "true"){
            if(window.Intercom){
                window.Intercom("hide");
            }

        }
        console.log("CLICK OUTSIDE MENEU",e.target.getAttribute("data-closeintercombreak"),e.target.getAttribute("data-closemodalmenu"));
        if( e.target.getAttribute("data-closemodalmenu") === "true"){
            if(window.Intercom){
                window.Intercom("hide");
            }

            changeShowUserMenu(false);
            changeShowNotifications(false);
        }
    }



    const [groupsSelectShowUpload, setGroupsSelectShowUpload] = useState(false);
    const [groupsSelectTransitionShowUpload, setGroupsSelectTransitionShowUpload] = useState(false);

    const changeGroupsSelectShowUpload = (variable) => {

        if(variable === true){

            setGroupsSelectShowUpload(variable);
            setTimeout(function() {
                setGroupsSelectTransitionShowUpload(true);
            },1);
        } else{

            setGroupsSelectTransitionShowUpload(false);
            setTimeout(function() {
                setGroupsSelectShowUpload(variable);
            },500);

        }

    };
    const [groupUploadType, setGroupUploadType] = useState(null);

    const changeGroupsSelectShowUploadWithType = (variable,type) => {


        if(variable === true){
            setGroupUploadType(type);
            setGroupsSelectShowUpload(variable);
            setTimeout(function() {
                setGroupsSelectTransitionShowUpload(true);
            },1);
        } else{
            setGroupsSelectTransitionShowUpload(false);
            setTimeout(function() {
                setGroupsSelectShowUpload(variable);
            },500);

        }

    };

    const closeModalOutsideClickUpload = (e) => {
        if( e.target.getAttribute("data-closemodal") === "true"){
            changeGroupsSelectShowUpload(false);
        }
    }

    const [fileInner, setFileInner] = useState(null);


    const setFileFunc = (value): void => {

        setFileInner(value);

    };

    const [warningMessage, setWarningMessage] = useState("");
    let triggerWarning = (warningMessage) => {

        setWarningMessage(warningMessage);
        handleTriggerWarning();
    };

    let handleTriggerWarning = () => {

    };


    useEffect(() => {

        if(fileInner && groupUploadType){
            setLoadProductsShow(true);
            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"uploads/prepare-form", {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer "+  getCookie('userTeam'), // notice the Bearer before your token
                },
            })
                .then(response => response.json())
                .then(function(data){
                    if(data.message !== undefined){
                        Sentry.captureException("edit uploads/prepare-form"+": "+ data.message + " : " + getCookie("userMail"));
                    }

                    let formData = new FormData();

                    Object.keys(data.formInputs).forEach((key) => {
                        formData.append(key, data.formInputs[key]);
                    });

                    // // const image = e.target.files[0];
                    // const texType = e.target.getAttribute("data-actionkey");
                    new Compressor(fileInner, {
                        quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
                        success: (compressedResult) => {

                            formData.append('file', compressedResult);

                            fetch(data.formAttributes.action, {
                                method: data.formAttributes.method,
                                body: formData,
                            })
                                .then(function(dataUpload){

                                    if(groupUploadType === "user"){
                                        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"user/current", {
                                            method: 'PUT',
                                            body: JSON.stringify(    {
                                                avatar_path : data.formInputs.key
                                            }),
                                            headers: {
                                                'Content-type': 'application/json',
                                                'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
                                            },
                                        })
                                            .then(response => response.json())
                                            .then(function(data){
                                                if(data.message !== undefined  || data.status === 500){
                                                    Sentry.captureException("user/current"+": "+ data.message + " : " + getCookie("userMail"));
                                                    //triggerWarning(data.message);
                                                    setLoadProductsShow(false);
                                                    if(data.error_code){
                                                        triggerWarning(data.error_code);
                                                    }else{
                                                        triggerWarning(data.message);
                                                    }
                                                }
                                                if(data.message === undefined){
                                                    
                                                        loadUserInfoAPI();
                                                }
                                            })
                                            .catch(function(e){
                                            Sentry.captureException(data.formAttributes.action+": "+ data.message + " : " + getCookie("userMail"));
                                                triggerWarning("There were some error :(");
                                                setLoadProductsShow(false);
                                            });
                                    }


                                    if(groupUploadType === "team"){
                                        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"teams/current", {
                                            method: 'PUT',
                                            body: JSON.stringify(    {
                                                logo_path : data.formInputs.key
                                            }),
                                            headers: {
                                                'Content-type': 'application/json',
                                                'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
                                            },
                                        })
                                            .then(response => response.json())
                                            .then(function(data){
                                                if(data.message !== undefined || data.status === 500){
                                                    Sentry.captureException("teams/current"+": "+ data.message + " : " + getCookie("userMail"));
                                                    setLoadProductsShow(false);
                                                    if(data.error_code){
                                                        triggerWarning(data.error_code);
                                                    }else{
                                                        triggerWarning(data.message);
                                                    }
                                                }
                                                if(data.message === undefined){
                                                    loadUserInfoAPI();
                                                }
                                            })
                                            .catch(function(e){
                                                Sentry.captureException("teams/current"+": "+ data.message + " : " + getCookie("userMail"));
                                                triggerWarning("There were some error :(");
                                                setLoadProductsShow(false);
                                            });
                                    }

                                })
                                .catch(function(e){
                                    Sentry.captureException(data.formAttributes.action+": "+ data.message + " : " + getCookie("userMail"));
                                });

                        },
                    });



                });

        }
    }, [fileInner, groupUploadType]);

    const [loadProductsShow, setLoadProductsShowVariable] = useState(false);
    const [loadTransitionProductsShow, setLoadTransitionProductsShow] = useState(false);

    const setLoadProductsShow = (variable) => {
        if(variable === true){
            setLoadProductsShowVariable(variable);
            setLoadTransitionProductsShow(true);


        } else{


            setTimeout(function() {
                setLoadTransitionProductsShow(false);
                setTimeout(function() {
                    setLoadProductsShowVariable(variable);
                },500);
            },300);


        }

    };


    return (
        <>


            {
                typeEditorPlugin
                    ?
                    <MainLayout className={"editor"}
                                data-closemodalmenu={true}
                                onClick={(e)=> closeModalOutsideClickMenu(e)}>
                        {children}
                    </MainLayout>
                :
                    <>


                        <div >
                            {
                                showMainMenu
                                    ?
                                    <MainMenu
                                        showUserMenu={showUserMenu}
                                        showNotifications={showNotifications}
                                        changeShowNotifications={changeShowNotifications}
                                        changeShowUserMenu={changeShowUserMenu}
                                        breadcrumbsAPI={breadcrumbsAPI}
                                        versionIsLocked={versionIsLocked} setVersionIsLocked={setVersionIsLocked}
                                        teamCredits={teamCredits}
                                        setTeamCredits={setTeamCredits}
                                        sideMenuPage={sideMenuPage}
                                        typeShareView={typeShareView}
                                        productNameMaster={productNameMaster}
                                        productVersionNameMaster={productVersionNameMaster}
                                        handleClick={handleClick}
                                        hitTriggerShowRender={hitTriggerShowRender}
                                        typeEditor={typeEditor} masterProductUUID={masterProductUUID}  versionProductUUID={versionProductUUID}/>
                                    :
                                    null
                            }


                            {showSideMenu ?
                                <>
                                    <SideMenu className={` ${ size.width < 500 ? (  showMobileMenu ? 'activeMobile' : 'inactiveMobile' ) : ""}`} >
                                        <ul className={` mn-sub show`}>


                                            <li className={'userInfo'} key={'userInfo'}>


                                                <div className={'logo'}>
                                                    <div className={'logoWithoutImage'}
                                                         onClick={() => changeGroupsSelectShowUploadWithType(true, "user")}>

                                                        {
                                                            userAvatar ? <div style={{ position: 'relative' }}>
                                                                <FontAwesomeIcon className={'hovered'} icon={faPlus}
                                                                                 style={{
                                                                                     position: 'absolute',
                                                                                     top: '3px',
                                                                                     left: '50%',
                                                                                     transform: 'translateX(-50%)',
                                                                                 }} />
                                                                <img style={{
                                                                    position: 'absolute',
                                                                    top: '-12px',
                                                                    left: '0',
                                                                }}
                                                                     src={userAvatar} />
                                                            </div> : <div style={{ position: 'relative' }}>
                                                                <FontAwesomeIcon className={'hovered'} icon={faPlus}
                                                                                 style={{
                                                                                     position: 'absolute',
                                                                                     top: '3px',
                                                                                     left: '50%',
                                                                                     transform: 'translateX(-50%)',
                                                                                 }} />
                                                                <FontAwesomeIcon className={'unhovered'}
                                                                                 icon={faBuilding}
                                                                                 style={{
                                                                                     position: 'absolute',
                                                                                     top: '3px',
                                                                                     left: '50%',
                                                                                     transform: 'translateX(-50%)',
                                                                                 }} />
                                                            </div>
                                                        }


                                                    </div>
                                                </div>


                                                <div style={{
                                                    width: 'unset',
                                                    display: 'inline-block',
                                                    position:"relative",
                                                    left:"15px",
                                                    top:"-15px"
                                                }}>
                                                    <h5>{userName.length > 13 ? userName.substring(0, 13) + '...' : userName}</h5>
                                                    {/*<span>{userEmail}</span>*/}


                                                </div>
                                                {/*<a href="">English</a>*/}
                                            </li>

                                            <li key={'profile'}
                                                className={`${sideMenuPage === 'profile' ? 'active' : ''} `}>
                                                <Link href={'/profile'}><FontAwesomeIcon style={{
                                                    height: '1.1em',
                                                    marginRight: '10px',
                                                    color: 'lightgray',
                                                    filter: 'none !important',
                                                }}
                                                                                         icon={faUser} /><span>   <FormattedMessage {...messages.side_menu_items_profile}></FormattedMessage></span>
                                                </Link>
                                            </li>
                                            <li key={'security'}
                                                className={`${sideMenuPage === 'security' ? 'active' : ''} `}>
                                                <Link href={'/security'}><FontAwesomeIcon style={{
                                                    height: '1.1em',
                                                    marginRight: '10px',
                                                    color: 'lightgray',
                                                    filter: 'none !important',
                                                }}
                                                                                          icon={faLock} /><span> <FormattedMessage {...messages.side_menu_items_security}></FormattedMessage></span>
                                                </Link>
                                            </li>

                                            <li className={'teamInfo'} key={'teamInfo'}>


                                                <div className={'logo'}>
                                                    <div className={'logoWithoutImage'}
                                                         onClick={() => changeGroupsSelectShowUploadWithType(true, 'team')}>

                                                        {
                                                            teamAvatar ? <div style={{ position: 'relative' }}>
                                                                <FontAwesomeIcon className={'hovered'} icon={faPlus}
                                                                                 style={{
                                                                                     position: 'absolute',
                                                                                     top: '3px',
                                                                                     left: '50%',
                                                                                     transform: 'translateX(-50%)',
                                                                                 }} />
                                                                <img style={{
                                                                    position: 'absolute',
                                                                    top: '-12px',
                                                                    left: '0',
                                                                }}
                                                                     src={teamAvatar} />
                                                            </div> : <div style={{ position: 'relative' }}>
                                                                <FontAwesomeIcon className={'hovered'} icon={faPlus}
                                                                                 style={{
                                                                                     position: 'absolute',
                                                                                     top: '3px',
                                                                                     left: '50%',
                                                                                     transform: 'translateX(-50%)',
                                                                                 }} />
                                                                <img style={{
                                                                    position: 'absolute',
                                                                    top: '-12px',
                                                                    left: '0',
                                                                }}
                                                                     src={"/images/logo.svg"} />


                                                            </div>
                                                        }


                                                    </div>
                                                </div>


                                                {/*<div style={{*/}
                                                {/*    textAlign: 'left',*/}
                                                {/*    lineHeight: '3',*/}
                                                {/*    width: '60px',*/}
                                                {/*    display: 'inline-block',*/}
                                                {/*    float: 'left',*/}
                                                {/*}}>*/}
                                                {/*    <img src={teamAvatar ? teamAvatar : '/images/logo.svg'} />*/}
                                                {/*</div>*/}


                                                <div style={{
                                                    width: 'unset',
                                                    display: 'inline-block',
                                                    position:"relative",
                                                    left:"15px",
                                                    top:"-15px"
                                                }}>

                                                    {Object.keys(userTeams).map(key => {
                                                        if (getCookie('userTeamUUID') === userTeams[key]['uuid']) {
                                                            return <h5 style={{ marginBottom: '5px' }}
                                                                       key={userTeams[key]['uuid']}> {userTeams[key]['name'].length > 14 ? userTeams[key]['name'].substring(0, 14) + '...' : userTeams[key]['name']}</h5>;
                                                        }
                                                    })}


                                                </div>

                                            </li>

                                            <li key={'team'} className={`${sideMenuPage === 'team' ? 'active' : ''} `}>
                                                <Link href={'/team'}><FontAwesomeIcon style={{
                                                    height: '1.1em',
                                                    marginRight: '6px',
                                                    color: 'lightgray',
                                                    filter: 'none !important',
                                                }}
                                                                                      icon={faBuilding} /><span> <FormattedMessage {...messages.side_menu_items_team}></FormattedMessage></span>
                                                </Link>
                                            </li>

                                            {!appExternal ?
                                                <>

                                                    <li key={'members'}
                                                        className={`${sideMenuPage === 'members' ? 'members active' : 'members'} `}>
                                                        <Link href={'/members'}><FontAwesomeIcon style={{
                                                            height: '1.1em',
                                                            marginRight: '10px',
                                                            color: 'lightgray',
                                                            filter: 'none !important',
                                                        }}
                                                                                                 icon={faUsers} /><span><FormattedMessage {...messages.side_menu_items_members}></FormattedMessage></span>
                                                        </Link>
                                                    </li>



                                                    <li key={'billing'}
                                                        className={`${sideMenuPage === 'billing' ? 'active' : ''} `}>
                                                        <Link href="billing"><FontAwesomeIcon style={{
                                                            height: '1.1em',
                                                            marginRight: '10px',
                                                            color: 'lightgray',
                                                            filter: 'none !important',
                                                        }}
                                                                                              icon={faCreditCard} /><span><FormattedMessage {...messages.side_menu_items_billing}></FormattedMessage></span>
                                                        </Link>
                                                    </li>

                                                 {/*   <li key={'payments-credits'}
                                                        className={`${sideMenuPage === 'payments-credits' ? 'active' : ''} `}>
                                                        <Link href={'/payments-credits'}><FontAwesomeIcon style={{
                                                            height: '1.1em',
                                                            marginRight: '10px',
                                                            color: 'lightgray',
                                                            filter: 'none !important',
                                                        }}
                                                                                                          icon={faCoins} /><span><FormattedMessage {...messages.side_menu_items_payments_credits}></FormattedMessage></span>
                                                        </Link>
                                                    </li>*/}
                                                    {userRole !== 'billing' ?
                                                        <li key={'subscription'}
                                                            className={`${sideMenuPage === 'subscription' ? 'active' : ''} `}>
                                                            <Link href="/subscription"><FontAwesomeIcon style={{
                                                                height: '1.1em',
                                                                marginRight: '10px',
                                                                color: 'lightgray',
                                                                filter: 'none !important',
                                                            }}
                                                                                                        icon={faSignalBars} /><span><FormattedMessage {...messages.side_menu_items_subscription}></FormattedMessage></span>
                                                            </Link>
                                                        </li>
                                                        : null
                                                    }


                                                    {/*<li><a >  <FontAwesomeIcon style={{height: "1.1em",  marginRight: "5px", color: "lightgray", filter: "none !important"}}  icon={faGear}/>Preferences</a></li>*/}


                                                </> : null}


                                            <hr />
                                            <li
                                                key={'switchteams'}
                                                className={'switchTeamsBtn'}
                                                onClick={() => handleClickSwitchTeams()}
                                                // onClick={() => changeShowSwitchTeams(true)}

                                            ><span
                                                //     className={` ${showSwitchTeams ? 'active' : ''}`}
                                            >
                                                        <FontAwesomeIcon style={{
                                                            height: '1.1em',
                                                            marginRight: '10px',
                                                            color: 'lightgray',
                                                            filter: 'none !important',
                                                        }} icon={faArrowRightArrowLeft} />
                                                      <FormattedMessage {...messages.side_menu_items_switch_teams}></FormattedMessage></span>

                                            </li>


                                        </ul>

                                        <LoaderProducts
                                            style={{    position: "absolute", background:"var(--black-5,#F2F2F2)"}}
                                            className={`dashboard-products-loader-profile ${loadTransitionProductsShow ? 'showTransition' : 'hideTransition'} ${!loadProductsShow ? 'hiddeComplete' : ''} `}
                                        >


                                            <img src={'/logoAnimate.svg'} />
                                        </LoaderProducts>

                                    </SideMenu>
                                    <DialogSwitchTeams
                                        loadUserInfoAPI={loadUserInfoAPI}
                                        toggleSwitchTeams={(toggleSwitchTeams: () => void) => {
                                            handleClickSwitchTeams = toggleSwitchTeams;
                                        }}
                                        userTeams={userTeams}

                                    />

                                    <button className={'showSideMenuMobile'}
                                            onClick={showMobileMenu ? () => setShowMobileMenu(false) : () => setShowMobileMenu(true)}>
                                        <FontAwesomeIcon icon={faAnglesRight} />
                                    </button>
                                    <MainLayout
                                        data-closemodalmenu={true}
                                        onClick={(e) => closeModalOutsideClickMenu(e)}
                                        className={`   ${showSideMenu ? 'isSideMenu' : ''} ${isLogin ? 'isLogin' : ''} ${typeEditor ? 'editor' : ''}`}>
                            {children}
                        </MainLayout>
                    </>
                :
                <MainLayout
                data-closemodalmenu={true}
            onClick={(e) => closeModalOutsideClickMenu(e)}
            className={`  ${isLogin ? "isLogin" : ""} ${typeEditor ? "editor" : ""}`}>
        {children}
        </MainLayout>


}

</div>

                        <ModalsWrapper className={`groupsSelectCheck  ${groupsSelectTransitionShowUpload ? 'showTransition' : 'hideTransition'} ${!groupsSelectShowUpload ? 'hiddeComplete' : ''}`}
                                       data-closemodal={true}
                                       onClick={(e)=> closeModalOutsideClickUpload(e)}
                        >


                            <ModalWindow
                                className={'center modal_dialog_big'}
                                id={'groupsSelectProductDialog'}>

                                <div className={'modal-header'}>

                                    <h4>Upload picture</h4>
                                </div>

                                <div className={'modal-content'}>
                                    <span>Upload square image, JPEG, PNG or SVG.<br/>Minimum size 1024 x 1024 px recommended.</span>

                                    <ObaleroFileUploader setFile={setFileFunc}
                                                         file={fileInner}
                                                         isSmall={false}
                                                         closeModal={changeGroupsSelectShowUpload}
                                                         isGroup={true}/>
                                </div>

                                <div className={'modal-buttons'}  style={{textAlign:"center" }}>
                                    <GlobalButtonFinal style={{ width: 'unset', float:"none", marginRight:"15px" }}
                                                       onClick={() => changeGroupsSelectShowUpload(false)}
                                                       className={'borderBlue btn-circle'}>
                                        Cancel
                                    </GlobalButtonFinal>
                                </div>



                            </ModalWindow>

                        </ModalsWrapper>

                        <DialogAlert
                            toggleAlert={ (toggleAlert: () => void) => {
                                handleTriggerWarning = toggleAlert;
                            } }
                            alertType={"warning"}
                            message={warningMessage}
                        />




                    </>

}

</>
)
}